<script setup lang="ts">
const { hireFormUrl } = useAppConfig();
const navbar = ref();
const isScrolled = ref(false);
const unbranded = useUnbranded();
const route = useRoute();
onMounted(() => {
  useScroll(() => window, {
    onScroll(e) {
      if (!navbar.value) return;
      isScrolled.value = navbar.value.clientHeight < window.scrollY;
    },
  });
});

const mainNavDrawerEl = ref<HTMLInputElement | null>(null);

// close drawer on navigation change
watch(
  () => route.path,
  () => {
    if (mainNavDrawerEl.value) {
      mainNavDrawerEl.value.checked = false;
    }
  }
);
</script>
<template>
  <template v-if="!unbranded">
    <nav class="fixed transition-all duration-300 navbar z-999" :class="{
      'bg-base-100': isScrolled,
    }" ref="navbar">
      <div class="container justify-between">
        <div>
          <NuxtLink to="/" class="flex items-center gap-2 text-2xl text-gray-600">
            <AppLogo />
          </NuxtLink>
        </div>
        <div class="flex-none">
          <div class="flex items-center gap-2">
            <div v-if="route.path === '/'" class="relative hidden sm:block">
              <NuxtLink :to="hireFormUrl" class="btn btn-primary btn-sm">List a Job</NuxtLink>
            </div>
            <label for="main-nav-drawer" class="block cursor">
              <IconBars3 class="w-6 h-6 text-dark" />
            </label>
          </div>
        </div>
      </div>
    </nav>
    <div class="drawer drawer-end">
      <input id="main-nav-drawer" type="checkbox" class="drawer-toggle" ref="mainNavDrawerEl" />
      <div class="drawer-content">
        <slot></slot>
      </div>
      <div class="drawer-side z-[999999999]">
        <label for="main-nav-drawer" aria-label="close sidebar" class="drawer-overlay"></label>
        <ul class="min-h-full p-4 menu w-80 bg-base-200 text-base-content">
          <!-- Sidebar content here -->
          <li>
            <NuxtLink to="/states">Jobs By State</NuxtLink>
          </li>
          <li>
            <NuxtLink to="/jobs/search">Search Jobs</NuxtLink>
          </li>
          <li>
            <NuxtLink :to="hireFormUrl" class="btn btn-primary btn-sm">List a Job</NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </template>
  <template v-else>
    <slot></slot>
  </template>
</template>
